export const FormAction = {
    ADD: "ADD",
    EDIT: "EDIT",
    DUPLICATE : "DUPLICATE"
};

export const StepperSteps = {
    GUIDELINES_INTRODUCTION: { key: 'GUIDELINES_INTRODUCTION', label: 'Guidelines and Intro' },
    TECHNICAL: { key: 'TECHNICAL', label: 'Technical' },
    THEORETICAL: { key: 'THEORETICAL', label: 'Theoretical' },
    INDEPTH_KNOWLEDGE: {Key: 'INDEPTH_KNOWLEDGE', label: 'InDepth Knowledge'}
}

export const StagesType = {
    GUIDELINES_INTRODUCTION : "GUIDELINES_INTRODUCTION",
    TECHNICAL : "TECHNICAL",
    THEORETICAL : "THEORETICAL",
    INDEPTH_KNOWLEDGE: "INDEPTH_KNOWLEDGE"
}

export const GuidelineIntroductionType = {
    GENERIC_INSTRUCTIONS : "GENERIC_INSTRUCTIONS",
    RATINGS : "RATINGS",
    TIME_DISTRIBUTION : "TIME_DISTRIBUTION",
    SKILL_EVALUATION : "SKILL_EVALUATION",
    RESUME : "RESUME",
}

export const TechnicalType = {
    TECHNICAL_QUESTIONS_DISTRIBUTION : "TECHNICAL_QUESTIONS_DISTRIBUTION",
    TECH_STACK : "TECH_STACK",
    TECHNICAL_QUESTIONS : "TECHNICAL_QUESTIONS",
    SECTION_FEEDBACK : "SECTION_FEEDBACK",
}

export const TheoreticalType = {
    THEORETICAL_QUESTIONS_DISTRIBUTION : "THEORETICAL_QUESTIONS_DISTRIBUTION",
    THEORETICAL_QUESTIONS : "THEORETICAL_QUESTIONS",
}

export const InDepthKnowledgeType = {
    INDEPTH_KNOWLEDGE_QUESTIONS_DISTRIBUTION : "INDEPTH_KNOWLEDGE_QUESTIONS_DISTRIBUTION",
    INDEPTH_KNOWLEDGE_QUESTIONS : "INDEPTH_KNOWLEDGE_QUESTIONS",
}

export const CommentStatus = {
    UNRESOLVED:"UNRESOLVED",
    RESOLVED:"RESOLVED",
    DELETED:"DELETED",
}

export const GuideSectionCssClass = {
    INITIAL : "main-box",
    NEW_COMMENT_HOVER : 'blue-box',
    PREV_COMMENT_HOVER: "light-yellow-box",
    PREV_COMMENT_CLICK : "dark-yellow-box",
}
export const GuideCommentIconClass = {
    NEW_COMMENT : "blue-icon",
    PREV_COMMENT : "yellow-icon",
}

export const sectionTypes = {
    TIME_DISTRIBUTION: 'TIME_DISTRIBUTION',
    RATINGS: 'RATINGS'
}

export const resetExternalQuestion = () => {
    return JSON.parse(JSON.stringify({
      role: [],
      tags: [],
      type: null,
      topic: null,
      max_yoe: null,
      min_yoe: null,
      category: [],
      question: {
              link: "",
              name: "",
              statement: "",
              image_link: ""
          },
        solution: {
        solution1: {
            code: "",
            link: null,
            name: null,
            approach: "",
            time_complexity: "",
            space_complexity: null
        }
    },
      tech_stack:[],
      avg_time_taken:null,
      things_to_check:[""],
      difficulty_level:null,
      follow_up_questions:null,
    }));
  };
export const RADIO_BUTTON = 'radioGroup' 

export const GUIDE_APPROVAL_STATUS = {
     PENDING: 'PENDING',
     NOT_SENT: 'NOT SENT FOR APPROVAL',
     REJECTED: 'REJECTED',
     APPROVED: 'APPROVED'
}

export const CommentCssClass = {
        LIGHT_YELLOW_BG: 'light_yellow_bg',
        DARK_YELLOW_BG: 'dark_yellow_bg'
}
