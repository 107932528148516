import React from "react";

import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { CommentConstants, isHLDDesignV2Versions, isLLDDesignV2Versions } from "../../Constants/FeedbackConstants";

import './previewSection.scss';

import { AccordionSummary, AccordionDetails } from '../../Components/Accordion'
import { CommonTable } from "./CommonData";
import starIcon from "../../../../../assets/images/feedback/star-icon.svg";
import fadeStarIcon from "../../../../../assets/images/feedback/fade-star-icon.svg";
import { designTable } from "./Constant";
import { CodeBox } from "./CodeBox";
import { codeBoxHeading } from "./Constant";

const DesignSection = ({ interviewDetails, data, showFeedbackComments, renderAddCommentSection, renderViewUnresolvedCommentSection }) => {
  const star = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const [isCodingOpen,setIsCodingOpen] = React.useState(false);
  const [codeSolution,setCodeSolution] = React.useState('');
  const [totalTimeSpent, setTotalTimeSpent] = React.useState(0);

  const groupQuestions = (list, key) => {
    return list?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      console.log(rv)
      return rv;
    }, {});
  };

  
  React.useEffect(() => {
    if (data?.questions && data?.questions?.length > 0) {
      let count = 0;
      data?.questions?.forEach(question => {
        if (question && question?.time_spent) {
          count += +question?.time_spent;
        }
      });
      setTotalTimeSpent(count);
    }
  }, [data?.questions]);

  const renderRatings = (rating) => {
    return <Box display="flex" flexDirection="row" alignItems="center">
      {
        star?.map((item, index) => {
          if ((index + 1) <= rating) {
            return <Box><img src={starIcon} /></Box>
          }
          else {
            return <Box><img src={fadeStarIcon} /></Box>
          }
        })
      }
    </Box>
  }
  const renderAllQuestionBox = (questionList) => {
    if(!questionList){
      return;
    }

    {
     return (Object.keys(groupQuestions(questionList, 'group'))?.map((datum) => {
        return (
          <Box display="flex" flexDirection="column">
            {Object.keys(groupQuestions(questionList, 'group'))?.length === 1 && (datum !== 'undefined' && datum !== 'null') && <div className="theoretical-field-header">{datum}</div>}
            {Object.keys(groupQuestions(questionList, 'group'))?.length > 1 && <div className="theoretical-field-header">{(datum !== 'undefined' && datum !== 'null') ? datum : "OTHERS"}</div>}
            <Box>
              {
                groupQuestions(questionList, 'group')[datum]?.map((question, index) => {
                  return <>{renderQuestionBox(question, index)}</>

                })}
            </Box>
          </Box>
        )
      }))
    }
  }
  const renderQuestionBox = (question, index) => {
    const copyData = JSON.parse(JSON.stringify(designTable));
    for (let key in copyData) {
      copyData[key].data = question[copyData[key]?.apiKey];
    }

    const handleViewCode = () => {
      setIsCodingOpen(!isCodingOpen); 
      setCodeSolution(question?.solution_code);
    }

    const renderLabelValue = (label, value) => {
      var array ;
      var newArray = [];

      if(Array.isArray(value)){
        array = value || [];
      }else{
        array = (value && value?.split(/\n/)) || [];
      }
    
      array?.forEach(function (element) {
        if(element && element?.length>0){
          newArray.push("- " + element);
        }
      });

      return (
        value ?
          <Box display='flex' flexDirection={'row'}>
            <Typography className='design-label'>{label}:</Typography>

            <Box display='flex' flexDirection={'column'}>
            {newArray?.map((data, index) => { return (<Typography className='design-value'>{data}</Typography>) })
            }
            </Box>
          </Box>
          : <></>
      )
    }

    return (
      <Box className="box-container" display="flex" flexDirection="column" gap="10px">
        <Box className="box-header" display="flex" flexDirection="row" justifyContent='space-between' alignItems="center">
          <Box paddingTop="14px" width="74%">
            <Box display="flex" flexDirection="row">
              <Box>{index + 1}.&nbsp;</Box>
              <a className={`${question?.question_link ? "link" : ''}`} href={question?.question_link ? question?.question_link : null} target="_blank" rel="noreferrer">
                <Box display="inline-block" style={{ lineBreak: 'anywhere' }} className="quill-box" dangerouslySetInnerHTML={{ __html: (question?.question_statement||question?.question_asked) }}></Box>
              </a>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" gap="16px">
            <Box>
              Time Spent : <span>{question?.time_spent || 0} Mins</span>
            </Box>
            { question?.solution_code && <Box>
                <Button className="view-code"  onClick={handleViewCode}>View Code</Button>        
              </Box>
            }
          </Box>
        </Box>
        <Box className="box-body" display="flex" flexDirection="column" justifyContent="space-evenly" gap="20px">
          <Box display="flex" flexDirection="row">
            <Box className={question?.difficulty?.tag?.toLowerCase() === "easy" ? "chip green" : question?.difficulty?.tag?.toLowerCase() === "medium" ? "chip black" : "chip red"}>{question?.difficulty?.text}</Box>
            <Box display="flex" flexDirection="row" alignItems='center' gap='5px'>

              {renderRatings(question?.rating)}

              <Box>({question?.rating || 0}/10)</Box>
            </Box>
          </Box>
          <CommonTable tableData={copyData} />


         { 
           isHLDDesignV2Versions(interviewDetails) && <>
           
           {
            question?.requirement_gathering && Object.keys(question?.requirement_gathering)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>Requirement Gathering</Typography>
              {renderLabelValue('Functional', question?.requirement_gathering?.functional)}
              {renderLabelValue('Non-Functional', question?.requirement_gathering?.non_functional)}
            </Box>
          }

          {
            question?.estimation && Object.keys(question?.estimation)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>Estimation</Typography>
              {renderLabelValue('Assumptions', question?.estimation?.assumptions)}
              {renderLabelValue('Traffic Estimation', question?.estimation?.traffic_estimation)}
              {renderLabelValue('Capacity Planning', question?.estimation?.capacity_planning)}
            </Box>
          }


          {
            question?.high_level_design && Object.keys(question?.high_level_design)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>High Level Design</Typography>
              {renderLabelValue('Architecture', question?.high_level_design?.architecture)}
              {renderLabelValue('DB Choices', question?.high_level_design?.db_choices)}
              {renderLabelValue('Load Balancing & Caching', question?.high_level_design?.caching)}

            </Box>
          }


          {
            question?.performance_and_security && Object.keys(question?.performance_and_security)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>Performance & Security</Typography>
              {renderLabelValue('Sharding', question?.performance_and_security?.sharding)}
            </Box>
          }

          {
            question?.additional_information && Object.keys(question?.additional_information)?.length > 0 && 
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>Additional info</Typography>
              {renderLabelValue('Data Modeling & APIs', question?.additional_information?.data_modeling)}
              {renderLabelValue('Auth', question?.additional_information?.auth)}
              {renderLabelValue('Rate limiter & WAF', question?.additional_information?.rate_limit)}
            </Box>
          }
          </>
}

          { 
           isLLDDesignV2Versions(interviewDetails) && <>
           
           {
            question?.requirement_gathering && Object.keys(question?.requirement_gathering)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>Requirement Gathering</Typography>
              {renderLabelValue('Functional Requirements', question?.requirement_gathering?.functional_requirements)}
              {renderLabelValue('Edge Cases', question?.requirement_gathering?.edge_cases)}
            </Box>
          }

          {
            question?.low_level_design && Object.keys(question?.low_level_design)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>Low Level design</Typography>
              {renderLabelValue('Entity Model', question?.low_level_design?.entity_model)}
              {renderLabelValue('Class Design', question?.low_level_design?.class_design)}

            </Box>
          }

          {
            question?.api_design && Object.keys(question?.api_design)?.length > 0 &&
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>API Design</Typography>
              {renderLabelValue('API Design', question?.api_design?.api_design)}
            </Box>
          }

          {
            question?.db_schema && Object.keys(question?.db_schema)?.length > 0 && 
            <Box display='flex' flexDirection={'column'}>
              <Typography className='design-heading'>DB Schema</Typography>
              {renderLabelValue('DB Schema', question?.db_schema?.db_schema)}
            </Box>
          }
          </>
}

          {question?.comment?.length > 0 && <Box display="flex" flexDirection="row" gap="10px" className="extra-comment">
            <Box>Extra Comments :</Box>
            <Box display="flex" flexDirection="column">
              {
                question?.comment?.map(comment => {
                  return comment && comment?.length>0 ? <Box>&#8226; {comment}</Box> : <></>
                })
              }
            </Box>
          </Box>
          }
        </Box>
      </Box>
    )
  }

  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <>
      <CodeBox
        isDialogOpen={isCodingOpen}
        handleClose={() => setIsCodingOpen(false)}
        solutionCode={codeSolution}
        type="design"
        heading={codeBoxHeading?.DESIGN}
      />
    
      <Accordion id="add-comment-parent" className="question-container" style={{ marginBottom: '20px' }} expanded={expanded} onChange={handleChange('panel1')}>

        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box className="theory-question-header" style={{ width: "100%", alignItems: "center" }}>
            <Box className="theory-question-header-text">
              <Typography className="theory-question-title">DESIGN QUESTIONS</Typography>
              <Typography className="theory-question-time">Time Spent: {totalTimeSpent} Mins</Typography>
            </Box>
          </Box>
          {showFeedbackComments && renderViewUnresolvedCommentSection(CommentConstants.CODING_QUESTIONS)}
          {showFeedbackComments && renderAddCommentSection(CommentConstants.CODING_QUESTIONS)}
        </AccordionSummary>

        <AccordionDetails>
          <Box className="coding-question-body" display="flex" flexDirection="column" style={{ width: "100%" }}>
            {renderAllQuestionBox(data?.questions)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>

  )
}


export default DesignSection;